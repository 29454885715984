import Author1 from "../../assets/author/IMG_8807.jpg";
import Author2 from "../../assets/author/IMG_8805.jpg";
import Author3 from "../../assets/author/B56-26.jpg";
import Author4 from "../../assets/author/_AMB6912.jpg";
import StickyBox from "react-sticky-box";

import "./styles.scss";
import { useEffect, useRef, useState } from "react";

const Author = () => {
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  const [imageToShow, setImageToShow] = useState(Author1);

  const inViewport1 = useIntersection(section1, '0px');
  const inViewport2 = useIntersection(section2, '0px');
  const inViewport3 = useIntersection(section3, '0px');
  const inViewport4 = useIntersection(section4, '0px');

  useEffect(() => {
    if (inViewport1) {
      setImageToShow(Author1);
    } else if (inViewport2) {
      setImageToShow(Author2);
    } else if (inViewport3) {
      setImageToShow(Author3);
    } else if (inViewport4) {
      setImageToShow(Author4);
    } else {
      setImageToShow(imageToShow);
    }
  }, [inViewport1, inViewport2, inViewport3, inViewport4]);

  return (
    <div className="author">
      <section className="masthead-slide">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 data-aos="fade-in">Meet the Author</h1>
              <p data-aos="fade-in">Otunba (Dr.) Adebola Ismail Akindele</p>
            </div>
          </div>
        </div>
      </section>
      <section className="author-content">
        <div className="container">
          <div className="row justify-content-center flex-row-reverse">
            <div className="col-lg-5 offset-lg-1s">
            <StickyBox offsetTop={120} offsetBottom={20}>
              <div className="row justify-content-center">
                <img
                  className="author-img img-fluid d-none d-lg-block"
                  src={imageToShow}
                  alt="author"
                />
              </div>
              <h3 className="d-none d-lg-block">Adebola Akindele</h3>
              </StickyBox>
            </div>
            <div className="col-lg-7">
              <h4 data-aos="fade-in" ref={section1}><i className="lni lni-layers"></i> Background</h4>
              <p data-aos="fade-in">
                <b>Otunba (Dr.) Adebola Ismail Akindele</b>, otherwise known as
                “Sir Bolly”, was born on 25 November 1963 in Ibadan, Oyo state.
              </p>

              <h4 data-aos="fade-in"><i className="lni lni-graduation"></i> Education</h4>
              <p data-aos="fade-in">
                He obtained post-primary education at Ansar-Ud-Deen College,
                Isolo, Lagos, between 1974 and 1979, then proceeded to the
                University of Ife, Ile Ife (now Obafemi Awolowo University
                (OAU)) in 1979 where he obtained his Bachelor of Science in
                Animal in 1985. He later bagged his master’s degree in Banking
                and Finance in 1993 from the University of Lagos and holds a
                Doctor of Business Administration from the prestigious
                International School of Management, Paris.
              </p>

              <h4 data-aos="fade-in"><i className="lni lni-briefcase"></i> Career</h4>
              <p data-aos="fade-in">
                Dr. Adebola started his professional career as an audit intern
                with KPMG Peat Marwick Ani Ogunde & Co. in 1987. Two years
                later, he joined the Central Bank of Nigeria (CBN) where he rose
                to become Treasurer/Financial Controller of the Agricultural
                Credit Guarantee Scheme (ACGS). While at CBN, he also served as
                a Bank Examiner on various audit assignments.
              </p>
              <p data-aos="fade-in">
                After gaining considerable public service experience, Dr.
                Adebola sought new challenges in the private sector, and in
                1993, he joined the Oceanic Bank Plc. where he held various
                senior management positions including Head of Operations, Chief
                Inspector, Head of Credit Administration & Loan Recovery, and
                Head, Commercial Banking Division at different times.
              </p>
              <p data-aos="fade-in">
                In 2000, Dr. Adebola pitched his tent with Fountain Trust Bank
                Plc. as the Group Head of Lagos Island Business Group. He rose
                in quick succession to become the Group Head, Commercial
                Banking, and later, the Divisional Head of Markets.
              </p>

              <h4 data-aos="fade-in"><i className="lni lni-apartment"></i> Courteville</h4>
              <p data-aos="fade-in">
                By 2004, after almost twenty years of successfully handling
                increasing responsibilities in different organisations in the
                Public and Private Sectors, Dr. Adebola set out to explore
                entrepreneurship by promoting Courteville Investments Limited
                which became a forerunner in Business and Process automation,
                not only in Nigeria but also in Sub-Saharan Africa. The company
                pioneered auto vehicle registration in Nigeria with the
                household trademark “AutoReg”.
              </p>
              <p data-aos="fade-in">
                Courteville Investment Limited grew in leaps and bounds and was
                listed on the Nigerian Stock Exchange in 2008. In 2011, the
                company underwent a major rebranding and was renamed
                “Courteville Business Solutions Plc”.
              </p>
              <p data-aos="fade-in">
                Courteville Business Solutions Plc has been recognised for its
                commitment to talent nurturing and employee fulfilment with Star
                Leadership awards from the prestigious Great Place to Work
                Institute.
              </p>

              <h4 data-aos="fade-in" ref={section2}><i className="lni lni-certificate"></i> Professional Affiliations</h4>
              <p data-aos="fade-in">
                Dr. Adebola is a Fellow of both the Institute Chartered
                Accountants of Nigeria (ICAN) and the Chartered Institute of
                Taxation respectively. He is also affiliated with the Nigerian
                Institute of Management (NIM), Institute of Directors (IoD),
                National Institute of Marketing of Nigeria (NIMN) and
                Professional Excellence Foundation of Nigeria (PEFON).
              </p>
              <p data-aos="fade-in">
                He is an active member of the World Business Forum, Africa CEO
                Forum, and the Commonwealth Business Forum. He is the only
                African on the Advisory Board of the Enterprise and
                Parliamentary Dialogue International (EPDI), a UK-registered
                independent not-for-profit body set up to establish a bridge of
                understanding between parliamentarians and enterprises. He is
                also first person of non-East African to sit on the Board of the
                Advisors of the East Africa Business Network (EABN), a position
                which he currently occupies.
              </p>
              <p data-aos="fade-in">
                He is the Chairman, Virtuality Consulting Limited, Bolbis
                Ventures, Leading Traders Company, Dajayaal Limited and Regis &
                Reinas Hospital. He sits on the Board of Synergy Capital and
                Advisory Limited, and is the Grand Patron of JCI Nigeria
                LASPOTECH, Ikorodu.
              </p>

              <h4 data-aos="fade-in"><i className="lni lni-users"></i> Mentor</h4>
              <p data-aos="fade-in">
                A visionary leader and icon to the next generation, Dr. Adebola
                is a mentor to over 200 protégés which include his staff,
                top-notch tech entrepreneurs and businessmen.
              </p>
              <p data-aos="fade-in">
                In a bid to share his vast knowledge of national development, he
                is a yearly Keynote Speaker and Panelist at the FINTECH
                conference, LEAD awards and other notable conferences.
              </p>
              <p data-aos="fade-in">
                He spearheads the bi-annual Courteville Business 3-Day Free
                Skill Acquisition Program which empowers teens, youths, and
                senior citizens to drive a new era of entrepreneurship
                development, growth, and productivity. In three years of its
                operation, the initiative has trained and empowered over 2,000
                community stakeholders in various vocational skills.
              </p>

              <h4 data-aos="fade-in"><i className="lni lni-crown"></i> Traditional Recognitions</h4>
              <p data-aos="fade-in">
                Dr. Adebola holds traditional titles such as Otunba Bobaselu of
                EjirinLand, Atunluse of Egbaland, Fimogboye of Apo-Owu in
                Abeokuta and Otunba Tayese of OgijoLand both in Lagos State. A
                philanthropist and community leader, he further has to his
                honour streets named after him in Yaba, Lagos, and in Ibara GRA,
                Abeokuta.{" "}
              </p>

              <h4 data-aos="fade-in" ref={section3}><i className="lni lni-night"></i> Religious Life</h4>
              <p data-aos="fade-in">
                Dr. Adebola Ismail Akindele is a devout Muslim. He holds the
                Islamic titles of Baba Adinni and Balogun Adinni of Olorun Gbebe
                Central Mosque in Mushin, Lagos.
              </p>
              <p data-aos="fade-in">
                For five years running, Dr. Adebola also sponsors “The
                Guidance”, an informative, educative, enlightening and
                soul-inspiring television broadcast that runs throughout the
                holy month of Ramadan.
              </p>

              <h4 data-aos="fade-in"><i className="lni lni-star"></i> Awards and Recognition</h4>
              <p data-aos="fade-in">
                In 2014, Ravensbourne University London, a UK digital media and
                design institution, awarded Dr Adebola an Honorary Fellowship in
                recognition of his pioneering leadership role in online learning
                in Africa.
              </p>
              <p data-aos="fade-in">
                In 2015, the Nigerian Red Cross inducted him as an Associate
                Member in its Platinum category for his contribution to the aid
                of medically vulnerable individuals, especially women and
                children.
              </p>
              <p data-aos="fade-in">
                In 2017, he was conferred with the Award of the Lagos State Man
                of the Year for 2017.
              </p>
              <p data-aos="fade-in" ref={section4}>
                In 2018, he received the 2018 Lifetime Achievement award from
                Nigeria Technology Awards.
              </p>
              <p data-aos="fade-in">
                Dr. Adebola is also a Merit Awardee of The Nigerian Union of
                Journalists (NUJ), a distinguished Alumni Awardee of both the
                Ansar-Ud-Deen Isolo and Obafemi Awolowo University, Ile Ife, and
                is a Paul Harris Fellow of The Rotary Foundation of Rotary
                International.
              </p>

              <h4 data-aos="fade-in"><i className="lni lni-grow"></i> Philanthropy</h4>
              <p data-aos="fade-in">
                Dr. Adebola Akindele strongly upholds his commitment to serving
                people by giving back to his community at large. He currently
                sponsors the education of not less than 50 non-Muslims in
                Primary, Secondary and Tertiary Institutions.
              </p>
              <p data-aos="fade-in">
                He restocked and rehabilitated the Beef Cattle Unit building for
                the Teaching and Research Farm at OAU, Ile-Ife, instituted the
                development of a crèche for the Centre for Gender and Social
                Policy Studies at OAU, Ile-Ife (on-going) and fully supports the
                Bisi Akindele Scholarship Award for Indigent Female Students of
                Obafemi Awolowo University. He also makes donations to several
                orphanages, Therapeutic homes for the disabled and Old Peoples’
                homes.
              </p>
              <p data-aos="fade-in">
                In 2016, he single-handedly renovated and refurbished the UNILAG
                Senior Staff Club and was honoured with an Appreciation Award
                and Honorary Membership of the Club.
              </p>
              <p data-aos="fade-in">
                In 2017, he promoted and fully funded the Lagos State Under-15
                Bola Akindele Cup, a football tournament set up to foster youth
                development and identify raw talent among different local
                government areas within the state. The tournament has been held
                every year since then.{" "}
              </p>
              <p data-aos="fade-in">
                In 2018, the 110-year-old Abeokuta Sports Club, Nigeria’s first
                sports club, recognised his contributions to the state including
                the rebranding the Idi Ere section of the Egba Traditional
                Council at the Alake’s palace and inducted him as one of the
                Vice Patrons.
              </p>

              <h4 data-aos="fade-in"><i className="lni lni-home"></i> Personal Life</h4>
              <p data-aos="fade-in">
                Dr. Adebola is happily married to Mrs. Olabisi Sidiquat
                Akindele. They have four amazing children together and are
                members of the Ikoyi Club 1938 Lagos.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Author;

const useIntersection = (element: any, rootMargin: any) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
      const observer = new IntersectionObserver(
          ([entry]) => {
              setState(entry.isIntersecting);
          }, { rootMargin }
      );

      element.current && observer.observe(element.current);

      return () => observer.unobserve(element.current);
  }, []);

  return isVisible;
};
