import PaperbackImg from "./../../assets/images/paperback.svg";
import { useEffect } from "react";
import { useLocation } from "react-router";
import Chapter1To3 from "./chapters/Chapter1-3";
import Chapter4To6 from "./chapters/Chapter4-6";
import Chapter7To10 from "./chapters/Chapter7-10";
import "./styles.scss";
import { Link } from "react-router-dom";
import Introduction from "./chapters/Introduction";

const Book = () => {
  const location = useLocation();

  useEffect(() => {}, [location]);

  let content = null;
  let footerContent = "Glean Refreshing Insights on Leadership, Management and Life";
  if (location.pathname.includes("1-3")) {
    content = <Chapter1To3 />;
    footerContent = "Glean Refreshing Insights on Leadership, Management and Life";
  } else if (location.pathname.includes("4-6")) {
    content = <Chapter4To6 />;
    footerContent = "Raise your Leadership Game";
  } else if (location.pathname.includes("7-10")) {
    content = <Chapter7To10 />;
    footerContent = "Not just another Leadership Book";
  } else if (location.pathname.includes("introduction")) { 
    content = <Introduction />;
    footerContent = "Not just another Leadership Book";
  }

  return (
    <div className="book">
      <div className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">{content}</div>
          </div>
        </div>
      </div>
      <div className="order-section">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-3">
              <img
                src={PaperbackImg}
                alt="book-cover"
                data-aos="fade-in"
                className="img-fluid"
              />
            </div>
            <div className="col-md-5">
              <h1 data-aos="fade-in">
                {footerContent}
              </h1>
              <Link to="/order">
                <button className="btn btn-primary" data-aos="fade-in">
                  Order a Copy
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Book;
