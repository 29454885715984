import PaperbackImg from "./../../assets/images/book-launch2.jpeg";
import PanelSession from "./../../assets/images/panel-session.jpeg";
import { useForm, ValidationError } from "@formspree/react";
import Swal from "sweetalert2";

import "./styles.scss";
import { useEffect } from "react";

const BookLaunch = () => {
  const [state, handleSubmit] = useForm("mzboykon");

  useEffect(() => {
    if (state.succeeded) {
      Swal.fire(
        "Message Sent Successfully",
        "Thank you for reaching out. We would reach out to you very soon",
        "success"
      );
    }
  }, [state.succeeded])

  return (
    <div className="contact">
      <section className="masthead-slide">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 data-aos="fade-in">Book Launch</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-detail">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-7 text-center">
              <img
                src={PaperbackImg}
                alt="book-cover"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-5 text-center">
              <img
                src={PanelSession}
                alt="book-cover"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3 text-center">
            <button className="btn btn-launch">Launch the Book</button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BookLaunch;
