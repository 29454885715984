import "./styles.scss";

const socials = [
  {
    icon: <i className="lni lni-facebook-original"></i>,
    url: "https://web.facebook.com/Musings-of-the-Muse_Book-102210729165501",
  },
  {
    icon: <i className="lni lni-instagram-original"></i>,
    url: "https://www.instagram.com/musingsofthemuse_book/",
  }
];

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-centers">
          <div className="col-lg-7">
            <div className="footer-text-p">
              Powered by
              <img
                src={require('../../assets/images/st-logo.png')}
                alt="scribe-tribe"
                className="img-fluid"
              />
            </div>
            <p className="copyright">© {year || 2022} – ScribeTribe Africa. All Rights Reserved</p>
          </div>
          <div className="col-lg-5 col-md-12 address-pane">
            <div className="social-icons">
              {socials.map(({ icon, url }) => (
                <a href={url} target="_blank" rel="noreferrer">
                  {icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
