import Masonry from "react-masonry-css";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

import "./styles.scss";

export const breakpointColumnsObj = {
  default: 4,
  1200: 2,
  992: 1,
  700: 1,
  500: 1,
};

const pictures = Array.from(Array(60).keys());

const Gallery = () => {

  return (
    <div className="gallery">
      <section className="masthead-slide">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 data-aos="fade-in">Gallery</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="reviews-content">
        <div className="container" style={{ overflow: "hidden" }}>
          <PhotoProvider>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {pictures.map((item, index) => {
                return (
                  <div className="col-md-4s" key={index}>
                    <div
                      className="review-item-content h-100"
                      data-aos="fade-up"
                    >
                      <div
                        className="d-flex author-info align-items-center"
                      >
                        <PhotoView
                          key={index}
                          src={require(`../../assets/gallery/${item + 1}.jpg`)}
                        >
                          <img
                            src={require(`../../assets/gallery/${
                              item + 1
                            }.jpg`)}
                            alt="person"
                            className={`${
                              index === 11 ? "img-fluid img-zoom" : "img-fluid"
                            }`}
                          />
                        </PhotoView>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Masonry>
          </PhotoProvider>
        </div>
      </section>
    </div>
  );
};

export default Gallery;
