const Chapter4To6 = () => {
  return (
    <>
      <Chapter4 />
      <Chapter5 />
      <Chapter6 />
    </>
  );
};

export default Chapter4To6;

const Chapter4 = () => {
  return (
    <div className="chapter" data-aos="fade-in">
      <h1>Chapter 4</h1>
      <p className="chapter-title">ENTREPRENEURSHIP</p>
      <p>
        An entrepreneur is someone who is able to create a value system that
        offers solutions to an existing problem – that is, value that a third
        party other than the creator or his family would be willing to exchange
        for financial gains.
      </p>
      <p>
        When attempting to create a marketable value system, it is important to
        consider both the willingness and ability of the audience to buy what
        one is proposing.
      </p>

      <h4>Entrepreneurship Myths</h4>
      <i>The Myth of Wealth</i>
      <p>
        While there are many successful entrepreneurs, there are many more
        unsuccessful ones; hence, entrepreneurship, on its own, is not a
        guarantee of success or wealth.
      </p>
      <i>The Myth of Capital</i>
      <p>
        It is important to know where the funding for your venture will be
        sourced from; ideas alone do not make an entrepreneur. While ideas can
        be categorised as intellectual capital, they however do not have the
        ability to categorically make one a successful business owner.
      </p>

      <h4>Entrepreneurship and Leadership</h4>
      <p>
        There are four main functions of a manager/leader:{" "}
        <b>Initiating, Decisions, Execution and Action (I.D.E.A.)</b>
      </p>
      <p>
        While leaders and managers engage daily in IDEAs, there are also key
        components to facilitate the seamless application of these innovations.
      </p>

      <ul>
        {[
          "A leader must take into consideration that the team is a crucial variable to his success, and implement a means through which team goals can be achieved; this means that having interconnected leadership qualities is important for any manager or business owner.",
          "The interest of the leader in the organisation where the IDEA concept is being implemented must be totally relegated to the background in terms of the benefit that will accrue from the tasks of the IDEA.",
          "An effective and efficient leader is one who has instituted coherent systems that are responsible for the smooth operation of the organisation, even when the leader is unavailable.",
        ].map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  );
};

const Chapter5 = () => {
  return (
    <div className="chapter" data-aos="fade-in">
      <h1>Chapter 5</h1>
      <p className="chapter-title">NEXT STEPS</p>
      
      <div className="img-section">
          <div className="row justify-content-center">
              <div className="col-md-12">
                <img
                    src={require("../../../assets/books/image5.png")}
                    alt="image2"
                    className="img-fluid"
                />
              </div>
          </div>
      </div>

      <p>
        Consider the lifetime of a business as illustrated with a Bell Curve
        above.
      </p>
      <p>
        As the bell curve shows, when a business is birthed, it takes some time
        to nurture, then it experiences a rapid, continuous growth for a while
        before it begins to decline and eventually dies. This is what happens in
        telecoms and other areas of the IT industry characterised by constant
        innovation.
      </p>
      <p>
        The big question, then is: what next after the company has reached its
        peak?
      </p>

      <p>
        You cannot continue to be in the same space with the same thought
        process and expect to eventually make impactful progress. For you to
        move on and do greater things, you need to strive to do things
        differently. Any decision you finally decide to take, should be guided
        by these key principles of change, innovation, and progress.
      </p>

      <p>You next move should not be:</p>
      <p>
        The most <b>logical</b> <br /> The most <b>popular</b>
      </p>
      <p>
        When you realise that your next step does not have to be any of these,
        you start thinking of the next things you can do depending on the point
        you are in your business life. There are three things you can do:
      </p>
      <ol>
        {[
          "You can relocate the curve",
          "You can extend the lifecycle of the curve",
          "You can relocate the graph to another product",
        ].map((item) => (
          <li>{item}</li>
        ))}
      </ol>

      <h4>On Failure</h4>
      <p>
        Life is an undulating curve and failure is well a part of it. The goal
        is not to avoid failure but to:
      </p>

      <ol>
        <li>
          <b>Fail Well</b> – Not ambiguities. Be clear on why you failed so that
          you won’t repeat it.
        </li>
        <li>
          <b>Fail Fast</b> – Move on. Don’t stay there.
        </li>
        <li>
          <b>Fail Forward</b> – Don’t repeat your mistakes. Do better. Fly
          higher.
        </li>
      </ol>
    </div>
  );
};

const Chapter6 = () => {
  return (
    <div className="chapter" data-aos="fade-in">
      <h1>Chapter 6</h1>
      <p className="chapter-title">LEADERSHIP</p>

      <p>
        Leadership is a multifaceted subject that is often misconstrued.
        Essentially, it is the ability to get people to work towards the
        objectives of the team without any compulsion.
      </p>
      <p>
        Often, when I talk about the subject of leadership, I like to note the
        inseparable relationship between leadership and followership: leadership
        cannot exist without followership. To be reckoned as a leader, a person
        must have followers; and you cannot be a good leader if you have not
        been a good follower.
      </p>
      <h4>The Loyalty Test</h4>
      <p>
        The essence of leadership is service. In other words, a true leader
        serves his followers. Real leaders do not need to call attention to
        their positions to prove that they are leaders – they just serve. It is
        through genuine service that they earn the trust of followers who
        consequently respond with respect, loyalty and commitment.
      </p>
      <p>
        As a follower emerging as a leader within a system, you must constantly
        prove your loyalty, particularly, to your job and the company and
        deliver the utmost value to your superiors. The test of loyalty
        strengthens the bonds of relationships. Paradoxically speaking, though
        loyalty does not have to be tested at all, it has to be proven all the
        time. And with proof of loyalty come strong and lasting relationships
        that mutually benefit the parties involved.
      </p>

      <h4>Leadership Skills and Qualities</h4>
      <p>
        <b>Vision</b> - recognise potentials in people
      </p>
      <p><b>Accessibility</b> - be approachable and accessible to really lead them</p>
      <p>
        <b>Inclusivity</b> – act with the big picture in view and taking everyone into
        consideration
      </p>
      <p><b>Intellectual Curiosity</b> – broaden the horizon of your knowledge</p>
      <p><b>T – Multi-tasking</b> – handle multiple tasks effectively</p>
    </div>
  );
};
