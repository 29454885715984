import Masonry from "react-masonry-css";
import "./styles.scss";

export const testimonials = [
  {
    detail:
      "I am pleased with the author's perception of humanity, leadership, management and life in general as he puts the issue the way he sees life. The book is a must-read for all Nigerians. It is, therefore, my privilege and honour to recommend it to policymakers, entrepreneurs, students and the general public.",
    author: "Air Vice-Marshal S. O. Smith (Rtd.), PhD",
    img: require("../../assets/testimonials/image9.png"),
    position: "",
  },
  {
    detail:
      "Musings of the Muse is a gift to all those involved in Micro, Small and Medium Enterprises (MSMEs) as well as intrapreneurial employees. It is for potential leaders looking for unique secrets to success.",
    author: "Remi Babalola",
    img: require("../../assets/testimonials/image7.png"),
    position:
      "Former Minister of State for Finance of the Federal Republic of Nigeria, Chairman, Board of Directors of FBN Holdings Plc",
  },
  {
    detail:
      "Musings of the Muse is indeed the reflection of an inspired professional who understands the value of transferring knowledge to the generations behind and posterity at large.",
    author: "Laoye Jaiyeola",
    img: require("../../assets/testimonials/image2.png"),
    position: "CEO, Nigerian Economic Summit Group",
  },
  {
    detail:
      "Adebola Akindele’s book on management, leadership and life could not have come at a better time. Given a post-COVID-19 world of rapidly moving economic, social and governance (ESG) parts, and the rise in uncertainty and governance complexity, finding a synthesis of ideas that help individuals and institutions navigate the bewildering rapids of perception, technology and work add inestimable value to societal growth and development.",
      author: "Olufemi M. AWOYEMI, FCA, FCTI, FCIB, FIoD",
    img: require("../../assets/testimonials/image1.png"),
    position: "Chairman and Founder, Proshare LLC",
  },
  {
    detail:
      "I hope that readers take the time to enjoy, ponder upon, learn and grow as a result of reading this book, and go on to impact positively their stakeholder network for the benefit of society at large.",
    author: "Z. Wuraola Abiola (Ph.D.)",
    img: require("../../assets/testimonials/image8.png"),
    position: "Managing Director, Management Transformation",
  },
  {
    detail:
      "Dr. Akindele's book is unique in how it analyses his personal journey with excellent literature but remains bold enough to test these shared principles taking a scientific approach to illustration and understanding. It is rare to see successful businesspeople and entrepreneurs share knowledge in detail and this remains a testament to Dr. Akindele's profound dedication to people development.",
    author: "Etop Ikpe",
    img: require("../../assets/testimonials/image10.png"),
    position: "CEO of Autochek",
  },
  {
    detail:
      "Musings of the Muse provides a detailed overview of the various stages everyone goes through in their professional life in the quest for growth. Dr Akindele shares his vast management experience in managing people and creating a conducive support system for staff members to perform.",
    author: "Ladi Sanni",
    img: require("../../assets/testimonials/image11.png"),
    position: "MD, Impala Energy Nigeria",
  },
  {
    detail:
      "Musings of The Muse succinctly captures the thoughts of Dr Bola Akindele on a diverse range of topics in a manner that provides us with a peep into the principles that have led him on his path to success. The book is an easy read and recommended to professionals and entrepreneurs on their way to building/making a mark in their areas of expertise.",
    author: "Irunna Ejibe",
    img: require("../../assets/testimonials/image6.png"),
    position: "CEO Nibra Designs Ltd",
  },
  {
    detail:
      "Dr. Bola Akindele has not only raised the bar in Leadership and Management literature, but he has also demonstrated the secrets of his being an accomplished practitioner of Leadership principles whose application is responsible for the establishment and meteoric growth of a first-class business conglomerate – Courteville Business Solutions Plc.",
    author: "‘Wale Oyenekan",
    img: require("../../assets/testimonials/image3.png"),
    position: "Managing Consultant, Long Strides Associates",
  },
  
  {
    detail:
      "The genius of this work resides in the fact that each of the ten chapters, offers a thoroughly fulfilling experience in growth and leadership. Its entirety and motivation are authentic because it is an honourable and honest representation of what the author thinks. What he feels. What he says. And what he always does … on leadership.",
    author: "Ken Njoku",
    img: require("../../assets/testimonials/image12.jpeg"),
    position: "CEO, Leadership Development Consulting",
  },
  {
    detail:
      "This is a very useful resource for senior managers looking to refresh their knowledge or learn new tips from one of the best and most experienced business managers out of Africa.",
    author: "Dr. Dipo Awojide",
    img: require("../../assets/testimonials/image4.png"),
    position: "Senior Lecturer in Strategy and Founder, Ambidextrous Consult",
  },
  {
    detail:
      "As the world continues to change and as we must evolve through the changes forced upon us by the outcomes of the COVID-19, there is hardly a better book to understand the future through the eyes of a man who’s navigated chaos and opportunities and has come out a muse whose musings we can’t but explore for our own ends and the benefit of our collective humanity.",
    author: "J. J. Omojuwa",
    img: require("../../assets/testimonials/image5.png"),
    position: "Founder and Chief Strategist, Alpha Reach ",
  },
];

export const breakpointColumnsObj = {
  default: 3,
  1200: 2,
  992: 1,
  700: 1,
  500: 1,
};

const Reviews = () => {
  return (
    <div className="reviews">
      <section className="masthead-slide">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 data-aos="fade-in">What People are Saying</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="reviews-content">
        <div className="container" style={{overflow: 'hidden'}}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {testimonials.map(({ author, detail, position, img }, index) => {
              return (
                <div className="col-md-4s" key={index}>
                  <div className="review-item-content h-100" data-aos="fade-up">
                    <div>
                      <i className="lni lni-quotation quote"></i>
                      <p className="detail">{detail}</p>
                    </div>
                    <div className="d-flex author-info align-items-center">
                      <img
                        src={img}
                        alt="person"
                        className={`${index === 11 ? "img-fluid img-zoom" : "img-fluid"}`}
                      />
                      <div>
                        <p className="author">{author}</p>
                        <p className="position">{position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Masonry>
        </div>
      </section>
    </div>
  );
};

export default Reviews;
