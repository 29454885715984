import "./styles.scss";
import Logo from "../../assets/images/logo2.png";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const getNavLinkclassName = (pathname: string) => {
    if (pathname === "/book") {
      // return location.pathname.includes("book") ? "nav-link dropdown-toggles activated" : "nav-link dropdown-toggle";
    }
    return location.pathname === pathname ? "nav-link active" : "nav-link";
  };

  const handleCollapse = () => {
    console.log("handleCollapse");
    let nav = document.getElementById("navbarSupportedContent");
    let btn = document.getElementById("navbarBtn");
    nav?.classList.remove("show");
    btn?.classList.add("collapsed");
  };

  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={Logo} alt="MUSINGS" className="img-fluid" />
          Musings
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          id="navbarBtn"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="lni lni-menu"></i>
          {/* <span className="navbar-toggler-icon"></span> */}
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className={getNavLinkclassName("/")}
                to="/"
                onClick={handleCollapse}
              >
                Home
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className={getNavLinkclassName("/book")}
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                The Book
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link
                    className="dropdown-item"
                    to="/book/introduction"
                    onClick={handleCollapse}
                  >
                    Introduction
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/book/chapter-1-3"
                    onClick={handleCollapse}
                  >
                    Chapter 1 - 3
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/book/chapter-4-6"
                    onClick={handleCollapse}
                  >
                    Chapter 4 - 6
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="/book/chapter-7-10"
                    onClick={handleCollapse}
                  >
                    Chapter 7 - 10
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                className={getNavLinkclassName("/author")}
                to="/author"
                onClick={handleCollapse}
              >
                The Author
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className={getNavLinkclassName("/events")}
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Events
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link
                    className="dropdown-item"
                    to="/events/book-launch"
                    onClick={handleCollapse}
                  >
                    Book Launch
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link
                className={getNavLinkclassName("/gallery")}
                to="/gallery"
                onClick={handleCollapse}
              >
                Gallery
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={getNavLinkclassName("/reviews")}
                to="/reviews"
                onClick={handleCollapse}
              >
                Reviews
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={getNavLinkclassName("/get-in-touch")}
                to="/get-in-touch"
                onClick={handleCollapse}
              >
                Get in Touch
              </Link>
            </li>
            <Link to="/order" onClick={handleCollapse}>
              <button className="btn-main navbar-togglers">Order a Copy</button>
            </Link>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
