// import "keen-slider/keen-slider.min.css";
import Masonry from "react-masonry-css";
import { Link } from "react-router-dom";
// import required modules
import { Autoplay, Navigation, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Author1 from "../../assets/author/IMG_8807.jpg";
import ArrowsImg from "../../assets/patterns/arrows.svg";
import Pattern2Img from "../../assets/patterns/section2-pattern1.svg";
import { breakpointColumnsObj, testimonials } from "../Reviews";
import HardcoverImg from "./../../assets/images/hardcover.png";
import PaperbackImg from "./../../assets/images/paperback.svg";
import BookLaunchImg from "./../../assets/images/book-launch2.jpeg";
import "./styles.scss";

const slides = [
  {
    title: "Refreshing Insights on Leadership, Management and Life",
    detail:
      "The genius of this work resides in the fact that each of the ten chapters, offers a thoroughly fulfilling experience in growth and leadership. Its entirety and motivation are authentic because it is an honourable and honest representation of what the author thinks. What he feels. What he says. And what he always does … on leadership.",
    author: "Ken Njoku",
    position: "CEO, Leadership Development Consulting",
    img: require("../../assets/testimonials/image12.jpeg"),
  },
  {
    title: "Not just another Leadership Book",
    detail:
      "Dr. Akindele's book is unique in how it analyses his personal journey with excellent literature but remains bold enough to test these shared principles taking a scientific approach to illustration and understanding. It is rare to see successful business people and entrepreneurs share knowledge in detail and this remains a testament to Dr. Akindele's profound dedication to people development.",
    author: "Etop Ikpe",
    position: "CEO of Autochek",
    img: require("../../assets/testimonials/image10.png"),
  },
  {
    title: "Raise your \n Leadership Game",
    detail:
      "Dr. Bola Akindele has not only raised the bar in Leadership and Management literature, but he has also demonstrated the secrets of his being an accomplished practitioner of Leadership principles whose application is responsible for the establishment and meteoric growth of a first-class business conglomerate – Courteville Business Solutions Plc.",
    author: "‘Wale Oyenekan",
    position: "Managing Consultant, Long Strides Associates",
    img: require("../../assets/testimonials/image3.png"),
  },
];

const socials = [
  {
    icon: <i className="lni lni-twitter-filled"></i>,
    url: "https://twitter.com/bolaakindele1",
  },
  {
    icon: <i className="lni lni-linkedin-original"></i>,
    url: "https://ng.linkedin.com/in/adebolaakindele",
  },
  {
    icon: <i className="lni lni-envelope"></i>,
    url: "mailto:bola@bolaakindele.com",
  },
];

const Home = () => {
  return (
    <div className="home4">
      <section className="masthead-slide">
        <div className="container-lgs">
          <Swiper
            // navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
            loop={true}
          >
            {slides.map(({ title, detail, author, position, img }: any) => {
              return (
                <SwiperSlide>
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-7">
                        <h3>{title.split('\n').map((item: any) => (
                          <>
                          {item} <br/>
                          </>
                        ))}</h3>
                        <div className="row">
                          <div className="col-lg-11">
                            <p className=" testimonial-detail">"{detail}"</p>
                            <div className="d-flex author-details">
                              <img
                                src={img}
                                alt="person"
                                className="img-fluid"
                              />
                              <div>
                                <p className=" testimonial-author">{author}</p>
                                <p className=" testimonial-position">
                                  {position}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="button-group ">
                          <Link to="/order">
                            <button className="btn btn-primary">
                              Order a Copy
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <img
                          // src={img}
                          // src={PaperbackImg}
                          src={HardcoverImg}
                          alt="book-cover"
                          className="img-fluid img-main d-none d-lg-block"
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
      <section className="masthead">
        <img
          className="arrows-img img-fluid d-none d-lg-block animate__animated animate__bounceIn"
          src={Pattern2Img}
          data-aos="fade-in"
          alt="Pattern2Img"
        />
        <div className="container">
          <div className="row flex-row-reverses align-items-center">
            <div className="col-lg-5">
              <img
                src={PaperbackImg}
                alt="book-cover"
                data-aos="fade-in"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-11">
                  <h1 data-aos="fade-in">Musings</h1>
                  <p data-aos="fade-in">
                    <b>Musings of the Muse</b> is a compendium of original
                    thoughts and insightful ideas on leadership, management and
                    life distilled from over three decades of corporate
                    experience in employment and entrepreneurship in the murky
                    African business terrain.{" "}
                  </p>
                  <p data-aos="fade-in">
                    {" "}
                    Flavoured with illustrations from the author’s personal and
                    professional life, it arrests the attention of the reader
                    whose appetite for wisdom is both deep and wide, compelling
                    him to reflect soberly, think better and get rid of barriers
                    to his accelerated growth.{" "}
                  </p>
                  <p data-aos="fade-in">
                    If this book rouses your curiosity for knowledge, enlightens
                    and excites your heart to see possibilities in spite of
                    numerous challenges, and empowers you to seize opportunities
                    with decisive actions, it would have fulfilled the purpose
                    for which it was written.
                  </p>
                </div>
              </div>
              <div className="button-group animate__animated animate__fadeInDown">
                <Link data-aos="fade-in" to="/order">
                  <button data-aos="fade-in" className="btn btn-primary">
                    Order a Copy
                  </button>
                </Link>
                <Link data-aos="fade-in" to="/book/chapter-1-3">
                  <button className="btn btn-secondary">
                    Read the Synopsis
                    <i className="lni lni-chevron-right"></i>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="author-section">
        <img
          className="arrows-img img-fluid d-none d-lg-block animate__animated animate__bounceIn"
          src={ArrowsImg}
          data-aos-delay="200"
          data-aos="fade-up"
          alt="ArrowsImg"
        />
        <div className="container">
          <div className="row align-items-center service-first">
            <div className="col-lg-7">
              <span data-aos="fade-in">— Meet the Author</span>
              <h1 data-aos="fade-in">Adebola Akindele</h1>
              <div className="social-icons" data-aos="fade-in">
                {socials.map(({ icon, url }) => (
                  <a href={url} target="_blank" rel="noreferrer">
                    {icon}
                  </a>
                ))}
              </div>
              <p data-aos="fade-in">
                A veteran business leader, Adebola Akindele is an active member
                of the World Business Forum, Africa CEO Forum, and the
                Commonwealth Business Forum.
              </p>
              <p data-aos="fade-in">
                He is the Founder and Group Managing Director of Courteville
                Business Solutions Plc, pioneer of auto vehicle registration in
                Nigeria with the household trademark ‘AutoReg’ and sits on the
                board of several companies.
              </p>
              <p data-aos="fade-in">
                He holds a Doctor of Business Administration from the
                International School of Management, Paris...{" "}
                <Link to="/author">Read more</Link>
              </p>
            </div>
            <div className="col-lg-4 offset-lg-1">
              <img
                className="author-img img-fluid d-none d-lg-block"
                data-aos="fade-in"
                src={Author1}
                alt="author"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="reviews">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 data-aos="fade-in">What people are saying</h1>
            </div>
          </div>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {testimonials.slice(0,6).map(({ author, detail, position, img }, index) => {
              return (
                <div className="col-md-4s" key={index}>
                  <div className="review-item-content h-100" data-aos="fade-up">
                    <div>
                      <i className="lni lni-quotation quote"></i>
                      <p className="detail">{detail}</p>
                    </div>
                    <div className="d-flex author-info align-items-center">
                      <img
                        src={img}
                        alt="person"
                        className="img-fluid"
                      />
                      <div>
                        <p className="author">{author}</p>
                        <p className="position">{position}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Masonry>
          <div className="text-center">
            <Link to="/reviews" data-aos="fade-up">
              <button className="btn btn-primary">See more reviews</button>
            </Link>
          </div>
        </div>
      </section>
      <section className="book-launch">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7">
              <img src={BookLaunchImg} alt="book-launch" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
