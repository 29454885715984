const Chapter7To10 = () => {
  return (
    <>
      <Chapter7 />
      <Chapter8 />
      <Chapter9 />
      <Chapter10 />
    </>
  );
};

export default Chapter7To10;

const Chapter7 = () => {
  return (
    <div className="chapter" data-aos="fade-in">
      <h1>Chapter 7</h1>
      <p className="chapter-title">MANAGING PEOPLE</p>
      <p>
        It is generally understood that human resources, though crucial to the
        actualisation of organisational objectives, are usually the most
        difficult resources to manage for apparent reasons. Different factors
        come into play in managing people: diversity of backgrounds, educational
        levels, experiences and exposure, drives, etc.
      </p>
      <p>
        As with anything in life especially that has to do with performance
        measurement, the workforce of any organisation can be analysed with a
        bell curve as shown below.
      </p>

      <div className="img-section">
          <div className="row justify-content-center">
              <div className="col-md-12">
                <img
                    src={require("../../../assets/books/image7.jpg")}
                    alt="image2"
                    className="img-fluid"
                />
              </div>
          </div>
      </div>

      <p>
        The bell curve groups employees into three major categories based on
        their performance, productivity and contribution to the system:
        non-performers, average performers and top performers.
      </p>
      <p>
        The poor players are often motivated by job security and are just happy
        to have an employment. In as much as they don’t earn much salary, they
        won’t take any risk that could lead to loss of their jobs; hence, they
        easily get lost in the crowd. They might be well-behaved, but they are
        not likely to contribute much either – and it is their poor output that
        makes them dispensable.
      </p>
      <p>
        The average players are the critical mass that accounts for the
        stability of the workforce. Unlike the poor performers, they don’t feel
        entitled to monthly allowances; rather, they are committed to their jobs
        and do what they can to contribute more than they earn.
      </p>
      <p>
        The top players are the rainmakers. They bring a wealth of experience,
        expertise and work ethic into their jobs and make things happen for the
        organisation. They usually stand out and are easily recognisable among
        the pack.
      </p>

      <h4>Building a Sustainable Workforce</h4>
      <p>
        Every entrepreneur wants to have outstanding performers within their
        workforce. In fact, more often than not, we hope for all our workers to
        be top performers but that is seldom the case. The big question then
        would be: how do we assemble a team of high flyers to increase the
        productivity of our organisation?
      </p>
      <p>
        First, it should be noted that performance and productivity are not
        determined by qualification. On the other hand, only a lazy entrepreneur
        would consider hiring outstanding performers as the only option to
        improve organisational productivity, especially as this set of people
        are usually expensive given the cost to the organisation in terms of
        their wages and allowances. If you are bootstrapping, you might not be
        able to afford them.
      </p>

      <h4>The Gamechanger</h4>
      <p>
        When employees are onboarded, they immediately fit themselves into a
        category in the bell curve. The progressive migration of employees from
        one category to another is crucial to the sustenance of any business.
        The real game of human resource management, therefore, lies in the bars
        that separates between the categories.
      </p>
      <p>
        Those within the thin line that separates the poor players from the
        average ones are usually striving to improve themselves and cross into
        the average category, perhaps, motivated by their Loss of Pay (LOP),
        lessons from real experiences as well as renewed ambitions. Those within
        the thin line that separates average performers from their outstanding
        counterparts are quite loyal, yet, ambitious to join the top performers
        club.
      </p>
      <p>
        Your resources will be best deployed to nurture the bars to improve the
        performances of ambitious employees. That way, poor performers can
        become average and average performers can become outstanding. The goal
        then is to widen the bars to accommodate as many employees as possible.
        In practical terms, you should have a pipeline for employees to get into
        the high flyer category.
      </p>
    </div>
  );
};

const Chapter8 = () => {
  return (
    <div className="chapter" data-aos="fade-in">
      <h1>Chapter 8</h1>
      <p className="chapter-title">WE SHOULD ALL BE HUMANS</p>
      <p>Basically, culture is a way of life peculiar to a group of people.</p>
      <p>
        There are two kinds of cultures that exist: people culture and corporate
        culture.
      </p>
      <h4>Dynamics of People and Corporate Cultures</h4>
      <p>
        People culture and corporate culture are interrelated. For example, as
        an entity, Courteville’s workforce constitutes of individuals from
        diverse background. However, if one is from the Yoruba tribe, Yoruba
        culture will be part of him by reason of his ancestry and exposure to
        the Yoruba way of life. The same goes for individuals from any other
        culture from around the world. Hence, as individuals, we are first part
        of a people culture before we subscribe to a corporate culture.
      </p>
      <p>
        Because of this dynamic, there could be issues when individuals bring
        the culture of their own personal lives that they have built over time
        to a company, making it difficult for them to fit into the corporate
        culture. In other words, the tribe culture as well as the character of
        the individual poses a barrier to the culture of the company.
        Understanding that people culture enables individuals to fit into the
        corporate culture, companies that have thrived with their cultures
        successfully translate the people culture into the company culture. When
        such happens, even when people leave the company, the impact and culture
        of the company still stays with them.
      </p>

      <h4>The Family Support System</h4>
      <p>
        One of the major features of the Courteville culture is the family
        support system. Generally, a support system is best described with the
        phrase “we are always with ours.” That is, we will always provide
        support to anyone of us through whatever they are going through.
      </p>
      <p>
        As a family, we know that we are made up of imperfect individuals.
        Hence, when someone misses his or her track, we trigger the support
        system to help that person get back to becoming their best self. We do
        this by introducing our 3 R’s: re-introduction, rehabilitation and
        re-integration.
      </p>
      <p>
        The kind of corporate support system that stand the test of time is not
        necessarily that which is written in policy documents but that which
        evolves over time within the people culture to meet the expectations of
        the support system. Our crèche and preschool evolved with the needs of
        our members, so also our health system which allows members access to
        standard medical facilities.
      </p>

      <h4>What Could Go Wrong</h4>
      <p>
        A family support system within and outside organisations should enable
        individuals look after one another. However, many people, especially the
        younger generation think that they can exist within being part of any
        community and their actions wouldn’t affect anyone other than
        themselves.
      </p>
      <p>
        Acting in your own interest alone without caring about how your actions
        will affect others might work for a while and, perhaps, help you to
        maintain your sanity, but it will prove unsustainable in the long run.
      </p>

      <h4>The Ultimate Goal</h4>
      <p>
        According to the Koran, there are three things that continue to give
        blessings to a man that is dead and gone: first, the righteous offspring
        he trains to continue in serving God; second, a project that continues
        to be of benefit to people; third, the beneficial knowledge he imparts
        to people that equips them to continue to be servants of God. In this
        case, I am focused on the third. I want to equip people with a sound
        message that remains relevant beyond my time of existence. I don’t care
        whether I am called weak or emotional as far as I serve God with a pure
        conscience and bequeath the knowledge I gain from doing so to others.
        You should aim to do the same.
      </p>
    </div>
  );
};

const Chapter9 = () => {
  return (
    <div className="chapter" data-aos="fade-in">
      <h1>Chapter 9</h1>
      <p className="chapter-title">SYNCHRONICITY</p>

      <h4>Making Sense of Chaos</h4>
      <p>
        As humans, we have the ability to plan and execute ideas; hence, we
        often overlook the place of divine providence in our achievements.
        Though atheists and agnostics might take offence at the idea of God, His
        existence and operations are real and true nonetheless.
      </p>
      <p>
        Synchronicity is the operation of the hand of God in the affairs of men.
        While we set goals and targets daily and work hard to achieve them,
        synchronicity affords us favourable opportunities beyond our
        imaginations to achieve and even surpass our goals. I have seen this
        work for me and many people again and again on a large scale.
      </p>
      <p>
        When we compare the achievements that we attain by careful planning and
        efforts with that which falls on our laps by reason of synchronicity, we
        realise that the latter far outweighs the former. Understanding this
        automatically fills our hearts with gratitude to God who is all-wise,
        all-powerful and ever kind.
      </p>

      <h4>Leveraging Synchronicity</h4>
      
      <div className="img-section">
          <div className="row justify-content-center">
              <div className="col-md-8">
                <img
                    src={require("../../../assets/books/image10.png")}
                    alt="image2"
                    className="img-fluid"
                />
              </div>
          </div>
      </div>

      <p>
        In the diagram above, the goals you set at the beginning of the year are
        represented by the blue set. At the end of the year, when you review
        your plans and accomplishments, you realise that you were able to
        achieve a fraction of the goals in the blue set as well as other
        achievements which you did not plan or pray for (represented by the
        green set). These are God’s blessings delivered to you by way of
        synchronicity. Asides from the set goals and additional achievements,
        there is also a pool of myriads of opportunities that are available to
        you, represented by the universal set.
      </p>
      <p>
        The big question then is, since our unplanned achievements are usually
        more than our planned ones, how do we ensure that the former do not
        reduce year after year? Or how do we even work to possibly expand it
        within the universal pool? How do we harness opportunities from the
        infinite universal pool?
      </p>
      <p>
        There are at least five ways you need to prepare for opportunities:
        spiritually, emotionally, mentally, physically and financially.
      </p>

      <p>
        <b>Spiritual Preparation</b> – draw closer to God to stay spiritually
        conscious and perceptive.
      </p>
      <p>
        <b>Emotional Preparation</b> – cultivate the capacity to manage your
        relationship with others with regards to your temperament and attitude
        to setbacks.
      </p>
      <p>
        <b>Mental Preparation</b> – be informed and resilient enough to make the
        right decisions when an opportunity presents itself.
      </p>
      <p>
        <b>Physical Preparation</b> – stay healthy and have necessary tools to
        take advantage of opportunities.
      </p>
      <p>
        <b>Financial Preparation</b> – have ready funds to cash in on
        opportunities.
      </p>
    </div>
  );
};

const Chapter10 = () => {
  return (
    <div className="chapter" data-aos="fade-in">
      <h1>Chapter 10</h1>
      <p className="chapter-title">THE SALMON RUN</p>
      <p>
        Salmons are a fatty kind of fish commonly found in the North Atlantic
        and in the Pacific. They possess a lot of oils, give out a lot of aroma
        and are very sweet to eat. The salmon starts its life as an egg in fresh
        waters, rivers and lakes, and its lifespan depends on where it lives in
        its first 2-8 years. It grows in the fresh water where it is able to
        gain access to resources that will sustain them through the first 3
        years or thereabout, then it starts preparing for its last journey.
      </p>
      <p>
        I believe that the life cycle of the Salmon fish has a lot to teach us
        humans about the purpose of life. In the Koran, we are made to know that
        even ants worship God. Every animal worship God in their own way. For
        humans, this is even more significant. The singularity of purpose in
        Islam is for a Muslim to be in servitude to God. Whatever we are doing
        in between is just part of the journey. The end of the journey is to be
        in servitude to God.
      </p>
      <p>
        For salmons, their life purpose is what determines what they do when
        they are here. From their birth to their death, everything they do is a
        preparation for their life’s purpose. For a human being, life starts
        from the release of eggs from a woman’s ovaries to development of a
        foetus in the womb, then the birth of a baby which proceeds to childhood
        and adulthood. From being a baby to an adult, the volume of activity we
        have to go through is why we are here making a living and trying to set
        up families.
      </p>
      <p>
        If the salmon run from birth to death is what the classic tale of
        fulfilling life purpose is, now imagine if a man says he knows what he
        is here to do and he goes towards it and delivers.
      </p>
      <p>
        It is interesting to note that if a salmon meets death in the ocean, it
        has fulfilled its life purpose because it was on the way of fulfilling
        its life purpose and not because it wants to have a boyfriend or
        girlfriend or build existing structures. A salmon that gets eaten by the
        bear on the way to fulfilling its purpose is not a failure in any way!
      </p>
      <p>
        When you fulfil God’s purpose, your LOP will not be relevant again
        because if you go again, there is nothing stopping you from committing
        sin.
      </p>
      <p>
        One Imam said that as a compass directs the salmon, so should it also
        guide the way of a man to know that once he comes out of his mother’s
        womb and he realises that there is a supernatural being that has gotten
        him here, the only thing that he needs to do is to start facing his way
        back.
      </p>
      <p>
        What is your life purpose? That uncanny compass should be in your
        conscience even in business. If your life purpose is to become the
        richest man in the world, your life compass will lead you there. Whether
        you achieve it or not does not matter. The point is that in this journey
        of man, you are going to be looking out for that life purpose and you
        need that input into what is supposed to be your life purpose.
      </p>
      <p>
        The Koran has 114 chapters. The first chapter changed my life. It gives
        us an opportunity to ask God, “What is my life purpose?” The remaining
        verses in the chapter are answers to that singular question. There are
        similar verses in the Bible that compels and guide us to think about the
        purpose of life. Some are as follows:
      </p>

      <i>
        “For I know the thoughts that I think toward you, says the Lord,
        thoughts of peace and not of evil, to give you a future and a hope”
        <b>(Jeremiah 29:11).</b>
      </i>
      <i>
        “A man’s heart plans his way, but the Lord directs his steps” <b>(Proverbs
        16:9).</b>
      </i>
      <i>
        “O Lord, I know the way of man is not in himself; it is not in man who
        walks to direct his own steps” <b>(Jeremiah 10:23).</b>
      </i>
      <i>
        “Therefore I say to you, do not worry about your life, what you will eat
        or what you will drink; nor about your body, what you will put on. Is
        not life more than food and the body more than clothing? Look at the
        birds of the air, for they neither sow nor reap nor gather into barns;
        yet your heavenly Father feeds them. Are you not of more value than
        they? Which of you by worrying can add one cubit to his stature? “So why
        do you worry about clothing? Consider the lilies of the field, how they
        grow: they neither toil nor spin; and yet I say to you that even Solomon
        in all his glory was not arrayed like one of these” <b>(Matthew 6:25-29).</b>
      </i>
    </div>
  );
};
