import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router";

import HomeLayout from "./layouts/HomeLayout";
import Author from "./screens/Author";
import Book from "./screens/Book";
import BookLaunch from "./screens/BookLaunch";
import Contact from "./screens/Contact";
import Gallery from "./screens/Gallery";
import Home from "./screens/Home";
import Order from "./screens/Order";
import Reviews from "./screens/Reviews";

const App = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/book/introduction" element={<Book />} />
        <Route path="/book/chapter-1-3" element={<Book />} />
        <Route path="/book/chapter-4-6" element={<Book />} />
        <Route path="/book/chapter-7-10" element={<Book />} />
        <Route path="/author" element={<Author />} />
        <Route path="/events/book-launch" element={<BookLaunch />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/get-in-touch" element={<Contact />} />
        <Route path="/order" element={<Order />} />
      </Route>
    </Routes>
  );
};

export default App;