import PaperbackImg from "./../../assets/images/paperback.svg";
import HardCoverImg from "./../../assets/images/hardcover.png";
import AudioBookImg from "./../../assets/images/audio-book.jpeg";
import "./styles.scss";

const Order = () => {
  return (
    <div className="order">
      <section className="masthead-slide">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 data-aos="fade-in">Order a Copy</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="order-content">
        <div className="container">
          <div className="row justify-content-center align-items-centers">
            <div className="col-md-9">
              <div className="row books">
                <div className="col-md-6">
                  <img
                    src={HardCoverImg}
                    alt="hard-cover"
                    style={{marginBottom: '15px'}}
                    className="img-fluid mt-4"
                  />
                  <p>Hardcover (₦7,000)</p>
                  <a href="https://selar.co/donm" target="_blank" rel="noreferrer">
                    <button className="btn btn-primary">Buy now</button>
                  </a>
                </div>
                <div className="col-md-6">
                  <img
                    src={PaperbackImg}
                    alt="paper-back"
                    className="img-fluid"
                    style={{ transform: "scale(0.82)" }}
                  />
                  <p>Paperback (₦5,000)</p>
                  <a href="https://selar.co/cuaw" target="_blank" rel="noreferrer">
                    <button className="btn btn-primary">Buy now</button>
                  </a>
                </div>
                {/* <div className="col-md-4">
                  <img
                    src={AudioBookImg}
                    alt="audio-book"
                    className="img-fluid"
                    style={{ transform: "scale(0.82)" }}
                  />
                  <p>Audio Book (₦2,500)</p>
                  <a href="https://selar.co/cuaw" target="_blank" rel="noreferrer">
                    <button className="btn btn-primary">Buy now</button>
                  </a>
                </div> */}
              </div>
            </div>
            <div className="col-md-4">
              <div className="platforms">
                <h4 className="mb-4">or</h4>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <p>Buy from Amazon Kindle</p>
                    <a href="https://www.amazon.com/dp/B0B21X77RN/ref=sr_1_1?crid=2Z42713M3GZNF&keywords=musings+of+the+muse+adebola+akindele&qid=1653352553&sprefix=musings+of+the+muse+adebola+akindele%2Caps%2C278&sr=8-1" target="_blank" rel="noreferrer">
                      <button className="btn btn-primary">
                        <img
                          src={require("../../assets/extras/amazon-kindle.png")}
                          className="img-fluid"
                        />
                      </button>
                    </a>
                  </div>
                  <div className="col-md-12">
                    <p>Buy from Sellar</p>
                    <a href="https://bit.ly/musingsofthemuse" target="_blank" rel="noreferrer">
                      <button className="btn btn-primary sellar">
                        <img
                          src={require("../../assets/extras/selar.png")}
                          className="img-fluid"
                        />
                      </button>
                    </a>
                  </div>
                  <div className="col-md-12">
                    <p>Buy from Okada Books</p>
                    <a href="https://okadabooks.com/book/about/musings_of_the_muse/48263" target="_blank" rel="noreferrer">
                      <button className="btn btn-primary okadabooks">
                        <img
                          src={require("../../assets/extras/okada-books.jpeg")}
                          className="img-fluid"
                        />
                      </button>
                    </a>
                  </div>
                  {/* <div className="col-md-12">
                    <p>Buy from Scribd</p>
                    <button className="btn btn-primary scribd">
                      <img
                        src={require("../../assets/extras/scribd.png")}
                        className="img-fluid"
                      />
                    </button>
                  </div> */}
                  <div className="col-md-12">
                    <p>Buy from Rakuten Kobo</p>
                    <a href="https://www.kobo.com/ww/en/ebook/musings-of-the-muse" target="_blank" rel="noreferrer">
                      <button className="btn btn-primary scribd">
                        <img
                          src={require("../../assets/extras/rakuten-kobo.png")}
                          className="img-fluid"
                        />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Order;
