import PaperbackImg from "./../../assets/images/paperback.svg";
import { useForm, ValidationError } from "@formspree/react";
import Swal from "sweetalert2";

import "./styles.scss";
import { useEffect } from "react";

const Contact = () => {
  const [state, handleSubmit] = useForm("mzboykon");

  useEffect(() => {
    if (state.succeeded) {
      Swal.fire(
        "Message Sent Successfully",
        "Thank you for reaching out. We would reach out to you very soon",
        "success"
      );
    }
  }, [state.succeeded])

  return (
    <div className="contact">
      <section className="masthead-slide">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 data-aos="fade-in">Get in Touch</h1>
              <p data-aos="fade-in">
                We'll be glad to hear from you. Please leave us a message.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-detail">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6">
              <img
                src={PaperbackImg}
                alt="book-cover"
                className="img-fluid d-none d-lg-block"
              />
            </div>
            <div className="col-lg-6">
              <form className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    className="form-control"
                    required
                  />
                  <ValidationError
                    prefix="Name"
                    field="name"
                    errors={state.errors}
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="you@mail.com"
                    className="form-control"
                    required
                  />
                  <ValidationError 
                    prefix="Email" 
                    field="email"
                    errors={state.errors}
                  />
                </div>
                <div className="form-group">
                  <label>Phone Number (Optional)</label>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    placeholder="080xxxxxxxxx"
                    className="form-control"
                  />
                  <ValidationError 
                    prefix="Phone"
                    field="phone"
                    errors={state.errors}
                  />
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    rows={6}
                    id="message"
                    name="message"
                    placeholder="Message"
                    className="form-control"
                    required
                  />
                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                  />
                </div>
                <div className="form-group text-right">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={state.submitting}
                  >
                    {!state.submitting ? "Send Message" : "Sending..."}
                  </button>
                </div>
              </form>
              <p>
                <i className="lni lni-phone-set"></i> Phone:{" "}
                <a href="tel:+2348028994830"> +234 802 899 4830</a>
              </p>
              <p>
                <i className="lni lni-envelope"></i> Email:{" "}
                <a href="mailto:musingsofthemuse@gmail.com">
                  musingsofthemuse@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
