const Introduction = () => {
  return (
    <div className="chapter" data-aos="fade-in">
      <p className="chapter-title">Introduction</p>
      <p>
        To paraphrase a number of global public personalities, one should never
        allow the opportunities presented by a good crisis to waste. I had been
        pushed several times over the past 10 years by many good-intentioned
        people and acquaintances to write or document some of my convictions on
        practical management and leadership practices, which have often been
        presented to audiences at various mentoring, speaking and lecture
        sessions over time. The period of the lockdown during the COVID-19
        pandemic provided me with the opportunity to put some of those thoughts
        together on paper.
      </p>
      <p>
        The Nigerian, and possibly the African business environment, have not
        aligned, even imperfectly, with the standard global economic and
        management theories. As such, home grown practices, especially in the
        Nigerian business landscape established from experiences of individuals
        and institutions over time, and strategies flexed by ‘street’ intuition
        and awareness, become most relevant to the success or even the survival
        of any business.
      </p>
      <p>
        Unfortunately, most of the documented local literature on successful
        institutions and managers/leaders have appeared as what discerning
        millennials consider ‘motivational illusions’ for the uncanny
        disconnection from the realities of the possibilities in access to
        national resources, business-centric infrastructure, and
        industry-friendly government policies. The very idea or impression in
        these records, suggesting the employment of global best practices in
        business and hard work, etc., as the only ingredients for success, is
        obviously quite overrated and mostly impractical for most businesses in
        Nigeria, especially start-ups. As the appreciation to the Almighty God
        that we are happy not looking like what we have been through in life,
        the projection of success in endeavours usually downplays the failures
        and several dips in business journey in these literatures.
      </p>
      <p>
        Most requests for a literature of my personal journey in business are
        usually tended towards the AutoReg story; one that would be a whole book
        in itself, but I would be showing here in this book, a few insights
        gained from my personal journey in employment and as an employer in
        public and private services, and as a manager/leader. The contents are
        entirely my personal stories or direct engagements in situations and
        firsthand accounts of colleagues and acquaintances. As such, they
        represent my views and convictions about various management and
        leadership practices.
      </p>
      <h4 style={{marginBottom: '4px'}}>Adebola Akindele</h4>
      <i>Lagos, Nigeria</i>
    </div>
  );
};

export default Introduction;
