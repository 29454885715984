const Chapter1To3 = () => {
  return (
    <>
      <Chapter1 />
      <Chapter2 />
      <Chapter3 />
    </>
  );
};

export default Chapter1To3;

const Chapter1 = () => {
  return (
    <div className="chapter" data-aos="fade-in">
      <h1>Chapter 1</h1>
      <p className="chapter-title">Professional Growth</p>
      <p>
        Growth should be the ultimate goal of every individual. At every point
        in our lives and professional career, growth is the key to long-term
        success.
      </p>
      <p>
        Professional growth refers to the acquisition and leverage of
        specialised knowledge and skillset to attain career goals. While this
        might be obtained largely from on-the-job experiential learning and
        coaching, off-the-job-environments also provide key avenues to be
        explored, especially when it involves skills and attributes that are
        easily transferable across spheres.
      </p>
      <p>
        The average wage earner is acquainted with the need for development and
        growth like other humans. However, because changes usually require
        uncomfortable steps, especially when it involves active participation in
        anything seen as work – whether or not it is hard work – only a few
        readily make and follow through with the decision to engage the process
        of development in order to experience growth.
      </p>
      <h4>The Pyramid of Professional Growth</h4>
      <p>
        The Pyramid of Professional Growth provides a simple, yet, detailed
        explanation of the requirements for progression at different levels of
        an individual’s career. It encourages the leverage of existing skills,
        gathering of relevant experiences, as well as acquisition of key
        competencies that enhances one’s capacity and qualification to perform
        higher roles effectively.
      </p>

      <div className="img-section">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <img
              src={require("../../../assets/books/img2.png")}
              alt="image2"
              className="img-fluid"
            />
            <p>Fig: Adebola’s Pyramid of Professional Growth</p>
          </div>
        </div>
      </div>

      <h4>THE GENERAL POOL</h4>
      <p>
        This level represents the pool of new entrants into the labour market.
        Fresh from the four walls of school, they hunt for available jobs in
        order to settle into a structure where they can gain exposure as well as
        deploy their knowledge and skills to add value.
      </p>

      <h5>Key Strategies for Navigating the General Pool</h5>
      <ol>
        {[
          "Think beyond Your Degree",
          "Develop a Plan",
          "Stay Positive",
          "Work on Weak Spots",
          "Don’t Wait for Your Dream Job",
        ].map((item) => (
          <li>{item}</li>
        ))}
      </ol>

      <h4>ENTRY LEVEL</h4>
      <p>
        At the entry or base level of the pyramid, some of the people from the
        general pool have successfully secured jobs and are now positioned in a
        corporate organisation. Most of these individuals have similar academic
        qualifications from tertiary institutions. They are usually onboarded as
        officers or line supervisors to learn the ropes of their career while
        working on procedures requiring minimal initiative.
      </p>

      <h5>Key Strategies for Navigating the Entry Level</h5>
      <ol>
        {[
          "Align Yourself with the Company Goals",
          "Work Hard and Smart",
          "Take Initiative",
          "Learn the Organisational Culture",
          "Prioritise Building a Solid Foundation",
          "Make the Most of Your Time and Resources",
        ].map((item) => (
          <li>{item}</li>
        ))}
      </ol>

      <h4>MIDDLE MANAGEMENT LEVEL</h4>
      <p>
        People in the management level have successfully transcended from the
        entry level with relevant qualifications and organisational training,
        thereby, setting themselves up for possible succession. They are more in
        tune with the organisational culture and are now at the intermediate
        level in the hierarchy. Their total salary package and benefit are also
        better than that of entry level workers.
      </p>

      <h5>Key Strategies for Navigating the Middle Management Level</h5>
      <ol>
        {[
          "Structure Your Work-life for Balance",
          "Prepare Strategically for the C-suite",
          "Improve Your Skills in People Management",
          "To those in C-Suite, be proactive",
          "To your Subordinates, earn their respect and allow feedbacks",
          "To other Middle Managers, build bridges rather than burning them",
        ].map((item) => (
          <li>{item}</li>
        ))}
      </ol>

      <h4>THE C-SUITE</h4>
      <p>
        The C-suite accommodates the highest-ranking executives in an
        organisation. Positions at this level are few. They are occupied by top
        executives who are also the most experienced personnel in the
        organisation. As veterans, they are the strategic thinkers and decision
        makers – the power and intellect behind the organisation.
      </p>

      <h5>Key Strategies for Navigating the C-Suite</h5>
      <ol>
        {[
          "Be Accountable and Responsible",
          "Be a Visionary",
          "Manage Complexities",
          "Be an Effective Communicator",
          "Be an Inspiration",
        ].map((item) => (
          <li>{item}</li>
        ))}
      </ol>
    </div>
  );
};

const Chapter2 = () => {
  return (
    <div className="chapter" data-aos="fade-in">
      <h1>Chapter 2</h1>
      <p className="chapter-title">STAKEHOLDER ANALYSIS</p>
      <p>
        Stakeholder analysis, a concept that enjoys wide applicability in
        project management, involves identifying the stakeholders of an intended
        project, allocating available resources among them on the basis of
        levels of interest and influence, and determining the optimal means of
        communication with the stakeholder groups. Stakeholders are the people
        who are involved or whose lives or routines will be impacted by the
        intended project.
      </p>
      <p>
        Every career is a project involving stakeholders apart from the
        professional himself irrespective of his status. Often times,
        individuals lose sight of the impact of their career transitions on
        stakeholders, resulting in avoidable frictions and conflicts. The same
        happens for major life transitions like marriage, child rearing,
        relocation, etc. Hence, when taking major decisions in our personal and
        professional affairs, we have to consider the people and parties that
        will be affected. Generally, these stakeholder relationships are in the
        forms of work obligations, parents, siblings, religious duties etc. My
        recommendation to upwardly mobile folks is to regularly draw up a
        Stakeholder Chart to analyse who and what are possibly affected as they
        are transiting from one level of the pyramid to another.
      </p>

      <div className="img-section">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <img
              src={require("../../../assets/books/img3.png")}
              alt="image2"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6">
            <img
              src={require("../../../assets/books/img4.png")}
              alt="image2"
              className="img-fluid"
            />
          </div>
        </div>
        <p>
          Stakeholder Analysis Chart highlighting resource allocations pre- and
          post-marriage
        </p>
      </div>

      <p>
        While the stakeholders do not have to be present and engage in the
        decision-making process, it is quite important to maintain the line of
        effective communication with them on decisions that will impact them.
        Communication will help to address possible issues ahead of time so as
        to avoid or, at least, minimise conflicts.
      </p>
    </div>
  );
};

const Chapter3 = () => {
  return (
    <div className="chapter" data-aos="fade-in">
      <h1>Chapter 3</h1>
      <p className="chapter-title">CONCEPT OF CHAOS AND OPPORTUNITIES</p>

      <h4>Making Sense of Chaos</h4>
      <p>
        In the first part of his signature book series, The Bourne Identity,
        Robert Ludlum defined chaos as a clashing of bodies in space. Imagine an
        empty space as vast as the sea area with bodies going about aimlessly,
        devoid of direction. Because the area is expansive and the bodies are in
        multitudes, the clashes and collisions will be infinite. The ultimate
        result of these frequent, never-ending collisions is the generation of
        heat, similar to the process observed while using a microwave oven. This
        simple analogy describes the situation of governance and infrastructure
        in Nigeria which leaves the youths dissatisfied and paranoid. The
        resulting heat is the apparent surge in social vices and irregularities
        being witnessed in the nation.
      </p>
      <p>
        The chaotic situation in Lagos and other commercial cities like Onitsha
        and Aba is particularly fascinating to me because of the economic
        opportunities that constantly show up as the interests of individuals
        and entities conflict. These cities easily attract people who are
        seeking to improve their financial situation because they present higher
        prospects. Such environments naturally propel individuals to become
        resilient to extreme adversity, resolute in achieving their aims, and
        resourceful in creating their own luck.
      </p>
      <p>
        Developed countries have settled economies and they are largely devoid
        of intense chaotic situations – which is part of why they are called
        developed economies in the first place. Because the systems in developed
        countries are quite settled, efficient and unchaotic, they cannot offer
        the kind of opportunities abundantly present in an imbalanced one like
        Nigeria.
      </p>
      <p>
        Opportunities arise from gaps and overlaps in systems, processes and
        procedures. In the case of Nigeria, the unfriendly situations are
        consequences of the absence of good infrastructure and amenities
        including transport, power, housing and security systems amongst others.
        These gaps and inefficiencies present opportunities for discerning
        innovators who can come up with creative ideas that will benefit the
        nation at large and make them smile to the bank. It would be safe to say
        that chaos and opportunities go hand in hand.{" "}
      </p>

      <h4>Spotting Strategic Opportunities in Chaos</h4>
      <p>
        You create opportunity yourself in solving a problem or fulfilling an
        anticipated pleasure. The former has a larger market though. Hence, when
        you solve problems that people are willing and able to pay you for,
        money and relevance will move in your direction.
      </p>
      <p>
        Another way to look at problems is the concept of needs. A need is
        something that is required for survival and sustenance. The
        insatiability of human needs creates problems that are disguised
        opportunities for wealth creation.
      </p>

      <div className="img-section">
        <div className="row justify-content-center">
          <div className="col-md-9">
            <img
              src={require("../../../assets/books/image6.png")}
              alt="image2"
              className="img-fluid bw"
            />
          </div>
        </div>
        <p>Figure: Maslow’s Hierarchy of Needs</p>
      </div>

      <p>
        From Abraham Maslow’s hierarchy of need, we can deduce that there are
        immediate opportunities around food, power (electricity), sex, etc.
      </p>
    </div>
  );
};
